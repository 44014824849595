import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import FormatHtml from 'components/utils/FormatHtml';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import AppPromotion from 'components/AppPromotion';

const FindSurveyorBody: React.FC = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "find surveyor body" } }) {
        html
      }
    }
  `);

  return (
    <Container section>
        <TitleSection title='Why Choose A Surveyor With Lively?' center />
        <FormatHtml content={markdownRemark.html} />
        <AppPromotion useTaskMessage={false} />
    </Container>
  );
};

export default FindSurveyorBody;
